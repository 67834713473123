.rfp-container{
    margin:0 auto;
    width: 40%;
    background-color: white;
    box-shadow: var(--shadow-minimal);
    padding: 3rem 2rem;
}

.rfp-form{
    display: flex;
    flex-direction: column;
}

.rfp-form > label{
    margin-bottom: .5rem;
}

.rfp-form button{
    align-self: flex-end;
}

.rfp-form > h3,
.rfp-form > input,
.rfp-form > select,
.rfp-form > textarea,
.rfp-form > table,
.rfp-form > button,
.rfp-form > p,
.rfp-form .h-seperator{
    margin-bottom: 1rem;
}

.rfp-form .h-seperator{
    background-color: var(--font-color);
}
.table.rfp,
.table.rfp *{
    width: unset;
    min-width: unset;
}
.table.rfp input{
    width: 100%
}

.table.rfp tr >td{
    padding: .2rem;
}

@media only screen and (max-width: 1600px) {
    .rfp-container{
        width: 60%;
    }
  }