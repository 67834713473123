.access{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 40%;
    margin: 30px auto;
}

.access span{
    font-size: 10rem;
    color:var(--red);
}

.access h3{
    margin-bottom: 15px;
}

.read td{
    filter: brightness(92%);
}

/* form */

.form__parent{
    background-color: var(--clr-hover-white);
    width: 100%;
    height: 100%;
    padding: 10px 0;
}

.form{
    background-color: white;
    width: 60%;
    height: 99%;
    margin: 0 auto;
    padding: 30px;
}

.form_loading{
    display:flex;
    align-items: center;
    gap:10px;
    height: 100%;
    justify-content: center;
}

.form_loading p{
    font-weight: var(--medium);
}

.form__body{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.form__body > div:first-child{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 1.1rem;
}

.form__body > div:last-child{
    width: 200px;
    border: 1px solid var(--clr-border);
    text-align: center;
    padding: 20px ;
    font-size: 1.3rem;
    font-weight: var(--bold);
    color: goldenrod;
    text-transform: uppercase;
    letter-spacing: .2ch;
}

.form__header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.form__header p{
    font-size: 1.1rem;
}

.action{
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
}

.settingSection{
    margin-bottom: 30px;
}