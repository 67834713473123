nav{
    height: 100vh;
    background-color: white;
    position: fixed;
    left: 0;
    padding: 1em 0;
    padding-right: .5em;
    max-width: 300px;
    width: 300px;
    overflow-x: hidden;
    box-shadow: var(--shadow-minimal);
    z-index: 10;
    transition: max-width 200ms linear;
}
/* nav,
nav *{
    transition: 200ms linear;
} */

nav>ul {
    list-style-type: none;
    height: 100%;
}

.nav-header{
    padding:1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nav-logo{
    border-radius: 50%;
    width: 60%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-logo img{
    object-fit: contain;
}


.nav-user{
    background-color: var(--green);
    align-self: flex-end;
    padding: 5px 20px;
    border-radius: 20px;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: var(--shadow-min-around);
    transition-delay: 100ms;
}

.nav-user > p{
    opacity: 1;
    transition-delay: 110ms;
}


.nav-link{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    padding: .8em .8em .8em 1.5em;
    text-decoration: none;
    font-weight: 700;
    color: var(--font-color);
    transition: 100ms !important;
}

.nav-link .nav-link-logo{
    min-height: 40px;
    min-width: 40px;
    box-shadow: 0px 4px 8px 0px rgba(0, 11, 106, 0.25);
    margin-right: .8em;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-link>span:nth-child(2){
    white-space: nowrap;
}

.nav-link>span:last-child{
    margin-left: auto;
}

.nav-link.active,
.nav-link:hover,
.dropdown-menu>a:hover,
.dropdown-menu>a.active{
    background-color: var(--primary-purple);
    color: white;
    border-top-right-radius: .7em;
    border-bottom-right-radius: .7em;
    transition: background-color 100ms;
}

.nav-link.active>span:first-child{
    background-color: white;
}

.nav.collapsed{
    /* width: calc(40px + 1.5em + .8em + .8em); */
    max-width: 100px;
    overflow: hidden;
    padding-right: 0;
    margin-right: 0;
}

.nav.collapsed a>span:nth-child(2),
.nav.collapsed a>span:nth-child(3){
    visibility: hidden;
}

.nav.collapsed div.nav-header > .nav-logo{
   visibility: hidden;
}

.nav.collapsed div.nav-header > .nav-user > p{
    transition-delay: 0s;
    opacity: 0;
}

.nav.collapsed div.nav-header > .nav-user{
    transition-delay: 0s;
    width: 0;
    opacity: 0;
}

.dropdown-menu.hide{
    max-height: 0;
    overflow-y: hidden;
    opacity: 0;
}

.dropdown-menu{
    margin-left: 1.5rem;
    background-color: var(--background);
    border-radius: 5px;
    max-height: 400px;
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
    opacity: 1;
}
.dropdown-menu>a{
    display: block;
    text-decoration: none;
    width: 100%;
    text-align: center;
    padding:1em 0;
    font-weight: 600;
    color: var(--font-color);
    transition: 100ms;
}

.nav-version{
    align-self: center;
    justify-self: flex-end;
    padding-top: 200px;
    margin-top: auto;
}

.nav-version p {
    text-align: center;
}