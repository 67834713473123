.table__body tr td{
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: normal;
}

.table__body tr td,
.table__head tr th{
    min-width: 0 !important;
}

.table__body,
.table__body tr{
    width: 100%;
    min-height: 100%;
}

.table__body tr td:first-child,
.table__head tr th:first-child{
    width: 20%;
    min-width: 20%;
    max-width: 20%;
}

.table__body tr td:nth-child(2),
.table__head tr th:nth-child(2){
    width: 15%;
    min-width: 15%;
    max-width: 15%;
}

.table__body tr td:nth-child(3),
.table__head tr th:nth-child(3){
    width: 15%;
    min-width: 15%;
    max-width: 15%;
}

.table__body tr td:nth-child(4),
.table__head tr th:nth-child(4){
    width: 38%;
    min-width: 38%;
    max-width: 38%;
}

.table__body > tr >td:last-child,
.table__head tr th:last-child{
    width: 12%;
    min-width: 12%;
    max-width: 12%;
}

.table__body > tr >td:last-child > button {
    display: inline;
    margin-right: 5px;
}




