.lasmain_err{
    width: 40%;
    margin-left: auto;
}

.parentWrapper{
    padding-top: 50px;
    position: relative;
}

.lasmain_loading{
    position: absolute;
    top: 0;
    left: 0;
}

.unreadCircle{
    display: none;
    background-color: var(--red);
    min-width: 25px;
    padding: 5px;
    border-radius: 100%;
    position: absolute;
    top: -10px;
    left: -10px;
}

/* DAshboard */

.dashboard{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.board{
    background-color: white;
    width: 250px;
    padding: 20px;
    border-radius: 10px;
    box-shadow:rgba(83, 109, 239, 0.459) 0px 15px 20px -20px;
}

.board > p {
    font-weight: 700;
    margin-bottom: 10px;
}

.board > span {
    font-size: 2rem;
}

.container{
    display: flex;
    gap: 10px;
}

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.header > p {
    font-weight: 700;
}

.leaveForm{
    padding: 20px 30px;
    border-radius: 10px;
    background-color: white;
    margin-top: 20px;
    width: 50%;
}

.rightContainer {
    margin-top: 20px;
    width: 50%;
}

.rightContainer div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.leaveForm > form {
    margin-top: 10px;
}

.leaveForm > form > button {
    align-self: flex-start;
    margin-left: auto;
    margin-top: 15px;
}

.panel,
.managerPanel{
    background-color: white;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
    padding: 10px 20px;
    min-height: 350px;
}

.managerPanel,
.managerBtns{
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
}

.managerBtns{
    margin-top: 20px;
}

.managerBtns > button{
    margin-bottom: 10px;
    align-self: stretch;
}

.success{
    background-color: var(--green);
    color: white;
    border-radius: 50%;
    padding: 5px;
}

.noPerm{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

/* Configuration Dialog */
.confManager{
    margin-bottom: 20px;
}


.confManager > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.confManager > div > p{
    min-width: 140px;
}


/* Tables */
.mtTableDiv{
    text-align: center;
    padding: 20px;
}

.mylistTable thead th:first-child,
.mylistTable tbody tr td:first-child{
    min-width: 50px !important;
    max-width: 50px !important;
    width: 50px;
    text-align: center;
}

.mylistTable{
    text-transform: capitalize;
}

.mylistTable thead th:last-child,
.mylistTable tbody tr td:last-child{
    min-width: 100px !important;
    max-width: 100px !important;
    width: 100px;
    text-align: center;
}

.unreadStyles td{
    background-color: #eee !important;
}

.tips{
    text-align: end;
    color:red;
    padding-top: 10px;
}

/* Details Dialog */

.details > div {
    margin-bottom: 10px;
}

.details p:last-child{
    font-weight: bold;
    text-transform: capitalize;
}

.details .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    gap: 10px;
}

/* Dashboard */

.totalLeaveContainer{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px 0;
}

.totalLeaveCard{
    background-color: white;
    min-width: 200px;
    padding: 10px;
    border-radius: 10px;
    box-shadow:rgba(83, 109, 239, 0.459) 0px 15px 20px -20px;
}

.totalLeaveCard p:first-child{
    font-weight: 700;
    font-size: 1.3rem;
}

.totalLeaveCard p:last-child{
    font-size: 2rem;
    text-align: end;
    margin-top: 10px;
}