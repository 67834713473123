.doc-inv-container{
   width: 60%;
   margin: 0 auto;
   height: 92%;
}

.doc-inv-indicator{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.doc-inv-indicator p{
    font-weight: var(--medium);
    background-color: #D8DDFE;
    padding: 1rem;
    border-radius: 10px;
    width: 121px;
    text-align: center;
}

.doc-inv-list{
    overflow-y: auto;
    max-height: 100%;
}

.doc-inv-list-item{
   padding: 1rem; 
   background-color: white;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #B9B9B941;
   cursor: pointer;
}

.doc-inv-list-item:hover{
    background-color: var(--clr-hover-item);
    font-weight: var(--bold);
    transition: var(--transition-normal);
}

.di-action{
    display: flex;
    gap:5px;
}

.di-action span{
    font-size: 1.3rem;
}

.tableInventory th, .tableInventory tr td{
    min-width: 0;
}

.tableInventory tr td:nth-child(1),
.tableInventory th:nth-child(1),
.tableInventory tr td:nth-child(2),
.tableInventory th:nth-child(2){
    min-width: max-content;
}