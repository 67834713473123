/* Prospect */

.loading__table{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 2rem 1rem;
    border-radius: 5px;
    gap: 1rem;
}

/* View Deal History Dialog */
.indicator{
    display: flex;
    align-items: center;
    padding: 1rem 0;
    gap: 1rem;
}

.history__list li{
    border: 1px solid var(--clr-border);
    border-radius: 5px;
    margin-bottom: .7rem;
    padding: .7rem;
    cursor: pointer;
    transition: background-color var(--transition-normal);
}

.history__list li:hover{
    background-color: var(--clr-hover-item);
}

.history__list li:active{
    background-color: var(--clr-active-item);
}

.history__list li div{
    display: flex;
    justify-content: space-between;
}

/* Search hit */
.hit{
    display: flex;
    color: black;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: var(--transition-normal);
}

.hit:hover{
    background-color: var(--clr-hover-white);
}
.hit div:first-child{
    width: 60%;
}
.hit div:last-child{
    width: 40%;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
}

.nomatch{
    color: black;
    text-align: center;
    margin: 20px auto;
}