.header{
    background-color: var(--primary-purple);
    padding: .6em 2em;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    user-select: none;
}

.dd-noti{
    margin-left: auto;
    background-color: #ffffff41;
    padding:.4em;
    border-radius: 50%;
    align-self: flex-start;
    cursor: pointer;
    position: relative;
}

.dd-profile{
    position: relative;
    background-color: #ffffff41;
    padding:.8em;
    border-radius: 50%;
    align-self: flex-start;
    cursor: pointer;
}

.dd-noti:hover,
.dd-profile:hover{
    background-color: #ffffff79;
}

.ddi-profile{
    position: absolute;
    top:100%;
    right: 0;
    color: black;
    width: max-content;
    font-weight: var(--bold);
    z-index: 2;
    transform-origin: right top;
    transform: scale(0);
    transition: transform 150ms;
}

.dd-profile.open div.ddi-profile{
    transform: scale(1);
}

.ddi-profile ul {
    list-style-type: none;
}

.ddi-profile ul li{
    padding: 1rem 2rem;
    background-color: white;
    border: 1px solid rgba(143, 143, 143, 0.322);
    display: flex;
    align-items: center;
    gap: .7rem;
}

.ddi-profile ul > li:last-child{
    color: red;
}

.ddi-profile ul li:hover{
    background-color: #f0f0f0;
}