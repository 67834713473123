.search{
    display: flex;
    align-items: center;
    gap:10px;
    background-color: white;
    padding: 10px 15px;
    border-radius: 20px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.search input{
    border: none;
    min-width: 300px;
}

.hit{
    position: absolute;
    left: 0;
    top: 100%;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    border: 1px solid var(--clr-border);
}

.hit p {
    padding: 10px;
    text-transform: capitalize;
    cursor: pointer;
}

.hit p:hover{
    background-color: var(--clr-hover-white);
    transition: 120ms;
}

.section__second{
    display: flex;
    gap: 10px;
    margin: 3em 0;
}

.img__comp,
.img__avatar,
.img__app{
    width: 120px;
    height: 120px;
    border: 3px solid #536defa9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: rgba(83, 109, 239, 0.459) 0px 25px 20px -20px;
}

.img__comp img{
    width: 90%;
    height: 90%;
}

.img__app img{
    width: 55%;
    height: 55%;
}

.img__avatar img{
    width: 100%;
    height: 100%;
}

.img__avatar{
    border-radius: 50%;
    overflow: hidden;
}

.div__avatar{
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.div__avatar span{
    background-color: rgba(199, 199, 199, 0.5);
    border-radius: 50%;
    padding: 1rem;
    cursor: pointer;
}

.div__avatar span:hover{
    background-color: rgb(240, 240, 240);
    transition: 120ms;
}

.widget__staff{
    min-width: 450px;
    background-color: white;
    padding: 10px;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.widget__staff > div:first-child {
    padding: 15px;
}

.widget__staff > div:last-child {
    padding:15px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.widget__staff > div:last-child > div {
    display: flex;
    gap: 10px;
    align-items: center;
}

.img__user{
    width: 60px;
    height: 60px;
    background-color: gainsboro;
    border-radius: 50%;
    overflow: hidden;
    cursor:pointer
}

@media only screen and (max-width: 1600px) {
    .img__user{
        width: 50px;
        height: 50px;
    }
}

.img__user:hover{
    filter:brightness(90%);
    transition:120ms
}

.img__user img{
    width: 100%;
    height: 100%;
}

.div__shortcuts{
    width: calc(100% - 400px) ;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    user-select: none;
    padding-left: 10px;
}

.div__shortcuts > div {
    width: calc(90%/3);
    max-width: 350px;
    height: 190px;
    cursor: pointer;

}

.shortcut{
    cursor: pointer;
    border-radius: 5px;
    padding: 20px;
    overflow: hidden;
    position: relative;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.shortcut:hover{
    filter: brightness(110%);
    transition: 120ms;
}

.shortcut > h1{
    color: white;
    font-size: 2.2rem;
}

.shortcut > span {
    color: white;
    opacity: .8;
    font-size: 15rem;
    position: absolute;
    bottom: -20px;
    right: -20px;
    transform: rotate(-40deg);
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 20px;
}

.shortcut__cal{
    background-color: var(--primary-purple);
}

.shortcut__deal{
    background-color: var(--sky-blue);
}

.shortcut__rep{
    background-color: var(--green);
}

.shortcut__dashboard{
    background-color: var(--orange);
}

.shortcut__rfp{
    background-color: #FFABAB;
}

.section{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 0;

}

.section > span{
    color: var(--primary-purple);
    position: relative;
}

.section > span::after{
    content: '';
    height: 4px;
    width: 100%;
    background-color: #536eef;
    position: absolute;
    bottom: -5px;
    left: 0;
    border-radius: 50px;
}

