.searchBox{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

.searchBox > span {
    background-color: gray;
    color: white;
    border-radius: 50%;
    padding: 4px;
}

.searchBox > input {
    flex-grow: 1;
    border-radius: 5px;
}

.list{
    max-height: 80%;
    overflow-y: scroll;
}

.userItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.userItem > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
}