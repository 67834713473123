.actionBtn{
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: .5rem;
  }

.form___ra{
    display: flex;
    gap: .7rem;
    flex-direction: column;
}