.container{
    overflow-x: scroll;
    padding-bottom: 1rem;
}

/* Remove constraint */
.table th,.table tr td{
    min-width: 0 !important;
}

.table_prosp th:nth-child(1),
.table_prosp tr td:nth-child(1),
.table_prosp th:nth-child(7),
.table_prosp tr td:nth-child(7),
.table_prosp th:nth-child(8),
.table_prosp tr td:nth-child(8),
.table_prosp th:nth-child(3),
.table_prosp tr td:nth-child(3),
.table_prosp th:nth-child(5),
.table_prosp tr td:nth-child(5){
    min-width: max-content;
    white-space: nowrap;
}