.titleCont{
    display: flex;
    align-items: center;
    margin-bottom: 1.8rem;
    gap: 1rem;
}
.title{
    color: var(--primary-purple);
}

.subtitle{
    margin-bottom: 1rem;
    display: flex;
}

.subtitle > span,
.listItem > h4:last-child > span {
    margin-left: 1rem;
    cursor: pointer;
    transition: var(--transition-normal);
}

.subtitle > span:hover,
.listItem > h4:last-child > span:hover{
    color: var(--primary-purple);
}

.list{
    margin-bottom: 2rem;
}

.listItem{
    display: flex;
    padding: 1rem 0;
    border-bottom: 1px solid var(--clr-border);
    align-items: flex-end;
}

.listItem *:not(input,select,option,span) {
    font-weight: var(--light);
    color: var(--font-color);
    text-transform: uppercase;
}

.listItem > h4:first-child{
    min-width: 20rem;
}

.listItem > h4:last-child{
    color: black;
    font-weight: var(--medium);
}

.actionBtn{
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: .5rem;
}

.tips{
    color: var(--primary-purple);
}

.inp50{
    width: 50%;
}

.miniSpinner{
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 50%;
    border: .5rem solid #f3f3f3;
    border-top: .5rem solid hsla(230, 83%, 63%, 0.61);
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

/* General */
.img__cont{
    width: 200px;
    height: 200px;
    background-color: gray;
    border-radius: 50%;
    margin-left: 10em;
    overflow: hidden;
}

.img__cont img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Deal */

.list__section{
    width: 30%;
}

.list__section ul{
    list-style-type: none;
}

.list__section li {
    padding: 1rem 1.2rem;
    cursor: pointer;
}

.list__section li .active{
    background-color: var(--primary-purple);
    color: white;
}

.cont__section{
    width: 70%;
    padding: 10px;
}


.item__style{
    display: flex;
    align-items: center;
    width: 500px;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-purple);
    padding: 10px;
}

.item__style:hover{
    background-color: var(--clr-hover-white);
    transition: var(--transition-normal);
}

.item__style span {
    cursor: pointer;
}

/* Users Settings */
.role {
    padding: 10px 0;
    border-left: 1px solid var(--primary-purple);
}

.role__header{
    color: var(--primary-purple);
    padding: 10px;
    background-color: rgb(241, 241, 241);
}

.role__items > h3{
    font-size: 1.3rem;
}

.role__items > p{
    margin-bottom: 10px;
}

.form__user input{
    width: 400px;
}

.form__role > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*
 Company Settings
 */

 .inputContainer{
     display: flex;
     align-items: center;
 }

 .inputContainer input{
     border-radius: 5px;
     min-width: 500px;
     max-width: 700px;
 }

 .inputContainer span{
     padding: 10px;
     cursor: pointer;
     user-select: none;
 }

 .inputContainer span.active{
     color: var(--red);
 }

 .inputContainer span:hover{
    color: var(--primary-purple);
    transition: 120ms;
 }

 .inputContainer span:active{
     color: var(--btn-purple-active);
 }

/*
     Staff & Management
 */

 .search{
     display: flex;
     align-items: center;
     gap: 10px;
 }

 .search input{
     border: 1px solid var(--clr-border);
     min-width: 400px;
     border-radius: 5px;
 }