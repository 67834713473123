

.pageLayout{
    width: 60%;
    height: 98%;
    border: 1px solid var(--clr-border);
    margin: 10px auto;
    padding: 30px;
    font-size: 1.2rem;
    position: relative;
    overflow: scroll;
    -webkit-overflow-scrolling: auto !important;
}

.pageLayout  *{
    font-family:Arial, Helvetica, sans-serif
}

.header,
.contentHead{
    display: flex;
    justify-content: space-between;
}

.imgContainer{
    max-height: 200px;
    max-width: 200px;
}

.imgContainer img{
    width:100%;
    height: 100%;
    object-fit: contain;
}

.address{
    max-width: 35ch;
}

.compName{
    font-weight: bold;
}

.regNum{
    letter-spacing: 1px;
    font-size: .9rem;
}

.contentHead{
    margin-bottom: 40px;
}

.status{
    border: 1px solid var(--clr-border);
    width: 300px;
    height: 100px;
    max-width: 300px;
    max-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.soStatus{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5rem;
}

.custName,
.services{
    text-transform: capitalize;
    padding: 10px 0;
}

.custAddress{
    max-width: 300px;
}

.title{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 40px;
}

.content{
    white-space: pre-wrap;
}

.sectionFooter{
    margin: 20px 0;
}

.actions,
.actions > div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}

.actions button{
    font-size: 1.2rem;
}

@media print {
    .pageLayout{
        width: 100%;
        height: 100%;
        border: none;
        margin: 0;
        padding: 30px;
        font-size: 1rem;
        position: relative;
    }

    .headerEnd{
        max-width: 200px;
    }

    .headerEnd h1 {
        font-size: 1.5rem !important;
    }

    .imgContainer{
        max-height: 120px;
        max-width: 150px;
    }

    .status{
        border: 1px solid var(--clr-border);
        width: 200px;
        height: 60px;
        max-width: 200px;
        max-height: 60px;
    }

    .soStatus{
        font-size: 1.2rem;
    }

    .title{
        font-size: 1.1rem !important;
        margin-bottom: 20px;
    }

    .contentHead{
        margin-bottom: 20px;
    }

    .custAddress{
        max-width: 200px;
    }
    
}