.cnd__form{
    display: flex;
    flex-direction: column;
    font-weight: var(--medium);
    gap: .5rem;
}

.cnd__form input,
.cnd__form option,
.cnd__form select,
.cnd__form textarea{
    font-size: 1.1rem;
}

.buttons{
    display: flex;
    gap:1rem;
    align-self: flex-end;
    margin-top: 10px;
}

.suggest__list{
    list-style-type: none;
    border: 1px solid var(--clr-border);
    border-bottom: none;
}

.suggest__li{
    display:flex;
    padding:.5rem;
    width:100%;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid var(--clr-border);
}

.suggest__li:hover{
    background-color: rgb(241, 241, 241);
}

.suggest__li:active{
    background-color: rgb(228, 228, 228);
}

/* Transfer Ownership */

.user_list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid var(--clr-border);
    border-radius: 5px;
    margin-bottom: 5px;
}

/* Search */

.hit{
    color: black;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid var(--clr-border);
}

.hit:hover{
    background-color: var(--clr-hover-white);
    cursor: pointer;
    transition: background-color var(--transition-normal);
}