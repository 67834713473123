.settings{
    display: flex;
    padding: 1rem;
    font-size: 1.2rem;
    width: 100%;
}

.settings-tabs{
    width: 20%;
}

.settings-tabs ul{
    list-style-type: none;
}

.settings-tabs li{
    padding: 1rem 2rem;
    cursor: pointer;
    transition: var(--transition-normal);
    margin-top: .8rem;
}

.settings-tabs li.active{
    font-weight: var(--bold);
    background-color: var(--primary-purple);
    color: white;
}

.settings-tabs li:hover{
    background-color: var(--primary-purple);
    color: white;
}

.settings-container{
    flex-grow: 1;
    border-radius: 10px;
    align-items: flex-start;
    border: 1px solid var(--clr-border);
    background-color: white;
    min-height: 500px;
    padding: 2rem;
}
