.modal__loading{
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
}

.loading__overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.212);
    z-index: 1000;
}

.ms{
    background-color: transparent;
    border-radius: 50%;
    border: .5rem solid #f3f3f3;
    border-top: .5rem solid hsla(230, 83%, 63%, 0.61);
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }