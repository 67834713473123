.hit{
    color: black;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.hit:hover{
    background-color: var(--clr-hover-white);
}

.load__more{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.date__field{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}


/* Calendar Grid */

.grid{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.grid > * {
    min-width: calc(100%/7);
    max-width: calc(100%/7);
    text-align: center;
}

.grid_header > h3 {
    text-align: center;
    margin-bottom: 10px;
}

.inactive_grid,
.active_grid,
.today_grid{
    height: 200px;
    border: 1px solid #E0E0E0;
    color: black;
}

.active_grid{
    display: flex;
    flex-direction: column;
    background-color: white;

}

.active_grid[breakday=true]{
    background-color: rgb(255, 228, 228);
     border: 1px solid #ff7f7f;
}

.active_grid[breakday=true] .foot{
    background-color: rgba(255, 198, 198, 0.822);
}

.today_grid{
    border: 1px solid #4a68ff;
}
.inactive_grid{
    background-color: #f5f4f4;
}

.body{
    height: 100%;
    overflow-y: hidden;
    padding: 5px;
    padding-bottom: 0;
}

.isOverflowing{
    z-index: 99;
    transform: scale(1.1);
    transform-origin: 50% 100% 0px;
    box-shadow: 0px 0px 20px 5px #000b6a1c;
    transition: var(--transition-normal) ease-in-out;
}

.isOverflowing > .body{
    background-color: white;
    height: max-content;
    overflow-y: visible;
}

.item{
    text-align:start;
    color: black;
    margin-bottom: 8px;
    border-radius: 5px;
    padding: 5px 10px;
    /* font-weight: var(--medium); */
    cursor: pointer;
    word-break: normal;
    word-wrap: break-word;
}

.item:hover{
    filter: brightness(115%);
}

.foot{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    padding-top: 5px;
    margin-top: auto;
    background-color: white;
}

/* LIST */

.oddStyles > td {
    filter: brightness(98%);
}

.today > td {
    background-color: #536eef10 !important;
}

.today:hover {
    background-color: var(--clr-hover-item) !important;
}

.staffTable{
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-bottom: 1px solid var(--clr-border);
}

.staffTable > div {
    padding: 10px;
}

.staffTable > div:last-child{
    margin-left: auto;
}

@media only screen and (max-width: 1600px) {

 
}