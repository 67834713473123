div.subheader {
    padding-bottom: 0 !important;
}

div.subheader>div:not(.tab) {
    margin-left: auto;
    margin-bottom: .5rem;
}

/*================= 
   Deals Table 
   =================*/

.button-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: .5rem;
    gap: .5rem;
}

/* .button-section.pagination{
   position: absolute;
   bottom: 2%;
   right: 2%;
} */

/*================= 
   Pipeline
   =================*/

.pipeline-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 1rem;
}

.pipeline-container.card {
    height: 80vh;
}

.pipeline-section {
    width: 400px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.pipeline-section.card {
    align-items: center;
    padding: 10px 0;
    border-radius: 5px;
    transition: background-color 120ms;
    min-height: 100%;
    gap: 0;
    justify-self: stretch;
}

.stage {
    background-color: var(--primary-purple);
    padding: 1.2rem 1rem;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.stage-name {
    font-size: 1.1rem;
    font-weight: var(--medium);
}

.stage-counter {
    min-width: 25px;
    font-weight: var(--medium);
    background-color: hsla(0, 0%, 100%, 0.418);
    padding: .5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pipeline-card {
    background-color: white;
    padding: .5rem .7rem;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    border: .5px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    gap: .3rem;
    position: relative;
    width: 99%;
    cursor: move;
    margin-top: 2px;
    margin-bottom: 2px;
}

.pipeline-card.dragging {
    opacity: .5;
}

.pipeline-card.over {
    margin-top: 30px;
}

div.card-status {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--green);
}

p.card-inq {
    font-weight: var(--regular);
    text-align: right;
}

p.card-name {
    font-weight: var(--medium);
}

p.card-value {
    font-weight: var(--bold);
}


/*================= 
   Archive
   =================*/

.archive-tab,
.option-tab {
    background-color: var(--primary-purple);
    width: max-content;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    min-height: 70px;
    height: 70px;
    box-shadow: var(--shadow-minimal);
}

.tab-name,
.option-tab-name {
    padding: 0 2rem;
}

/*================= 
   Activities List
   =================*/

.payment-info {
    display: flex;
    color: white;
    gap: 1rem;
    align-items: center;
}

.payment-info div {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.payment-info div>span:first-child {
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.activities-list {
    display: flex;
    height: 100%;
    gap: 1rem;
    margin: 0 5rem;
}

.left-side-container {
    width: 40%;
}

.right-side-container {
    width: 60%;
    height: inherit;
    overflow-y: scroll;
    padding: 0 10px;
}

.activity-card {
    display: flex;
    width: 100%;
    box-shadow: var(--shadow-minimal);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.activity-card p {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-weight: var(--medium);

}

.activity-card p:first-child {
    font-weight: var(--bold);
}

.event-info {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    background-color: var(--primary-purple);
    padding: 1rem;
    width: 25%;
    color: white;
}

.activity-info {
    padding: 1rem;
    background-color: white;
    width: 75%;
    display: flex;
}

.activity-info p {
    font-weight: var(--regular) !important;
}

.activity-info h3 {
    font-weight: var(--medium);
}

.activity-info>div {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.activity-info>div:first-child>div {
    display: flex;
    gap: 1rem;
}

.activity-info>div:last-child {
    margin-left: auto;
    align-self: flex-end;
}

.activity-filters div.filter {
    font-size: 1.1rem;
    padding: 1rem;
    border: 1px solid #00000041;
    color: #000B6A;
    background-color: white;
    transition: var(--transition-normal);
    width: 80%;
    cursor: pointer;
}

.activity-filters div.filter.active {
    background-color: var(--dark-purple);
    color: white;
    font-weight: var(--bold);
}

.activity-filters div.filter:hover:not(.active) {
    background-color: #DBE0FF;
}

.activity-filters div.filter:active:not(.active){
    background-color: #ABB7FF;
}

.activity-stats {
    background-color: white;
    border-radius: 5px;
    padding: 1rem;
    width: 80%;
    font-size: 1.1rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: var(--shadow-min-around);
}

.activity-stats>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--bold);
}

.activity-stats>div>p:last-child {
    color: var(--primary-purple);
}


/*================= 
   Activities Calendar
   =================*/

.cal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cal-header>div {
    display: flex;
    align-items: center;
}

.cal-month {
    text-transform: uppercase;
    font-weight: var(--bold);
    color: var(--dark-purple);
    font-size: 2rem;
    padding: 0 1rem;
    min-width: 250px;
    text-align: center;
}

.cal-btn {
    color: var(--primary-purple);
    font-size: 3rem;
    text-align: center;
    cursor: pointer;
    padding: 1rem 0;
    user-select: none;
    transition: var(--transition-normal);
}

.cal-btn:hover {
    color: hsl(230, 100%, 27%);
}

.cal-btn:active {
    color: hsl(230, 83%, 50%);
}

@media only screen and (max-width: 1600px) {

    .activities-list {
        gap: 0rem;
        margin: 0 5rem;
    }

    .left-side-container {
        width: 30%;
    }

    .right-side-container {
        width: 70%;
    }
}