.dialog__layout{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: max-content;
}

.banner__green{
    height: 40%;
    background-color: var(--green);
    width: 100%;
}

.banner__orange{
    height: 40%;
    background-color: var(--orange);
    width: 100%;
}

.banner__red{
    height: 40%;
    background-color: var(--red);
    width: 100%;
}

.circle{
    color: white;
    border: 5px solid white;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
}

.circle > span{
    font-size: 4rem;
}

.header__text{
    font-size: 2rem;
    margin: 1rem 0;
}

.ok__single__btn{
    margin-top: auto;
    margin-bottom: 2rem;
    border: 1px solid var(--primary-purple);
    width: 50%;
    display: table-cell;
    vertical-align: middle;
}

.infoText{
    text-align: center;
    padding: 0 2rem;
    margin-bottom: 1rem;
}

.buttonLay{
    display: flex;
    align-self:center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 2rem;
    gap: 1em;
}