.parentWrapper {
    width: 80%;
    height: max-content;
    margin: 0 auto;
    padding-bottom: 1rem;
}

.container {
    background-color: var(--background);
    border-radius: 5px;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.title{
    position: absolute;
    top: -2rem;
    left: 1rem;
    font-size: 1.2rem;
}

.tips{
    text-align: end;
    margin-top: auto;
    padding-top: 1rem;
    width: 80%;
    margin-left: auto;
}

.subSection{
    margin-bottom: 1rem;
    text-align: end;
}

.subSection > button{
    margin: 0;
    margin-left: auto;
}

.progress{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.progress .track{
    background-color: var(--clr-hover-white);
    margin-top: 8px;
    height: 10px;
    border-radius: 20px;
}

.progress .track > div{
    height: 100%;
    border-radius: 20px;
    background-color: var(--primary-purple);
    width: 0%;
    transition: width var(--transition-normal);
}

.valueText{
    font-size: 1.2rem;
    margin-top: 8px;
}

.balloon{
    background-color: white;
    border: 1px solid var(--clr-border);
    width: max-content;
    height: max-content;
    position: absolute;
    top: 110%;
    right: 0;
    padding: 10px;
    border-radius: 5px;
}

@media only screen and (max-width: 1600px) {
    .parentWrapper {
        width: 100%;
    }
  }