.parent{
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
}

.fetching{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-top: 10%;
}

/* Header */
.header{
    background-color: var(--primary-purple);
    padding: .6em 2em;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    user-select: none;
}

/* Stage Progress */
.stageProgress{
    background-color: var(--background);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25px;
    width: 95%;
    margin: 0 auto;
    padding-top: 10px;
}

.bar__active,
.bar__inactive{
    width: 12rem;
    height: .5rem;
}

.bar__active{
    background-color: #6BD488;
}

.bar__inactive{
    background-color: #e0e0e0;
}

.round__active,
.round__inactive{
    position: relative;
}

.round__active div,
.round__inactive div{
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    color: white;
}

.round__active div{
    background-color:#6BD488;
}

.round__inactive div{
    background-color:#e0e0e0;
}

.round__active div:hover,
.round__inactive div:hover{
    background-color:var(--primary-purple);
    transition: background-color var(--transition-normal);
}

.round__active p,
.round__inactive p{
    position: absolute;
    top: 130%;
    width: 300px;
    left: 50%;
    right: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}

/* Main Container */

.loading__cont{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.main {
    background-color: white;
    flex-grow: 1;
}

.main > div{
    background-color: var(--background);
    width: 95%;
    display: flex;
    margin: 0 auto;
    padding: 0 1%;
    padding-top: 1rem;
    height: 100%;
}

.main .left{
    width: 25%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}

.main .middle{
    background-color: white;
    width: 50%;
    overflow-y: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 1rem;
    padding: 1.5rem;
}

.main .right{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 1rem;
    position: sticky;
    top: 13px;
}

.f_col,
.f_col_exclude{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #ffffffad;
    border-radius: 10px;
    gap:.5rem;
    user-select: none;
    transition: 
        color var(--transition-normal);
}

.f_col:hover{
    color: var(--primary-purple);
    font-weight: bold;
    cursor: pointer;
}

.label {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.action{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.label2 {
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    border-radius: 10px;
    user-select: none;
    transition: 
        background-color var(--transition-normal),
        padding var(--transition-normal);
}

.label2:hover{
    background-color: #ffffffad;
    padding: 1rem;
    color: var(--primary-purple);
    font-weight: bold;
}

.label2:active,
.f_col:active{
    background-color: var(--clr-active-white);
    transition: var(--transition-normal);
}

.no__so{
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    padding: 1rem;
    background-color: rgba(241, 241, 241, 0.63);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 10px;
}

.record__item{
    display: flex;
    align-items: center;
    width: 100%;
}

.record__ind{
    display: flex;
    align-items: center;
}

.record__ind .round{
    width: 50px;
    height: 50px;
    background-color: teal;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.record__ind .bar{
    width: 25px;
    height: 3px;
}

.record__detail,
.record__detail_sh{
    flex-grow: 1;
    padding: 1rem;
    background-color: rgba(241, 241, 241, 0.322);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 13px;
}

.record__detail_sh{
    background-color: rgba(241, 241, 241, 0.63);
}


.no__record{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.sh__cont{
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.act__header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.act__actions{
    display: flex;
    align-items: center;
}

.act__actions span{
    border-radius: 50%;
    padding: .2rem;
    user-select: none;
}

.act__actions span:hover{
    cursor: pointer;
     background-color: var(--clr-hover-white);
     transition: background-color var(--transition-normal);
}

.act__loading{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.act__loading p {
    font-style: italic;
}

.notes{
    line-height: 1.6rem;
    white-space: pre-line;
}

.so__status{
    background-color: var(--primary-purple);
    border-radius: 5px;
    color: white;
    font-weight: var(--medium);
    padding: 2px 10px;
    text-transform: uppercase;
}

/* Detail Container */
.detail{
    margin-bottom: 1rem;
    box-shadow: var(--shadow-minimal);
    border-radius: 5px;
    overflow: hidden;
}
.detail__title{
    background-color: var(--dark-purple);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1rem;
}

.detail__title > span{
    border-radius: 50%;
    padding: .5rem;
    cursor: pointer;
    user-select: none;
    transition: background-color var(--transition-normal);
}

.detail__title > span:hover{
    background-color: rgba(255, 255, 255, 0.322);
}


.detail__children{
    padding: 1rem;
    background-color: white;
}

.row{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

/* Error Component */
.error__comp{
    background-color: var(--red);
    padding: 1rem;
    color: white;
    width: 40%;
    position: absolute;
    bottom: 0;
    right: 1rem;
    border-radius: 5px;
}

/* Dialog Add Activity */

.date__field{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}


/* Wall History */

.history{
    background-color: rgba(241, 241, 241, 0.322) ;
    margin-bottom: 10px;
    padding: 10px 5px;
    border-radius: 5px;
}

/* Assign Person */

.ap__cont{
    border: 1px solid var(--clr-border);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

