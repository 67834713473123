:root {
  --primary-purple: #536eef;
  --dark-purple: #000b6a;
  --background: #f5f6fd;
  --green: #6bd488;
  --sky-blue: #2bccff;
  --font-color: #91919d;
  --orange: #ff8139;
  --red: #fa3e3e;

  --spinner: hsla(230, 83%, 63%, 0.61);
  --clr-hover-item: #dce2ff;
  --clr-active-item: #c8d2ff;
  --clr-hover-white: hsl(0, 0%, 89%);
  --clr-active-white: hsl(0, 0%, 85%);
  --transition-normal: 120ms;
  --medium: 600;
  --bold: 700;
  --regular: 400;
  --light: 300;
  --layout-side-padding: 2rem;
  --shadow-minimal: 0px 0px 8px 2px rgba(0, 11, 106, 0.11);
  --shadow-purple-bottom-narrow: rgba(83, 109, 239, 0.459) 0px 25px 20px -20px;
  --shadow-min-around: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  --clr-border: #00000033;

  --btn-purple-active: hsl(230, 83%, 50%);
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
}

*::-webkit-scrollbar {
  width: 0.35rem;
  height: .6rem;
  background-color: var(--background);
  cursor: pointer;
}

*::-webkit-scrollbar-thumb {
  width: 25%;
  height: 60%;
  background-color: var(--dark-purple);
  border-radius: 20px;
}

body,
html,
#root,
.not-found,
.main-wrapper {
  height: 100%;
  width: 100%;
}

html {
  font-size: 13px;
}

body {
  box-sizing: border-box;
}

title{
  text-transform: capitalize;
}

/* Utility */

.fx{
  display: flex;
}

.fx-col{
  flex-direction: column;
}

.fx-center{
  align-items: center;
  justify-content: center;
}

.fx-gap-1{
  gap: 10px;
}

.fx-gap-2{
  gap: 20px;
}

.flex__row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.fx-jc-sb{
  justify-content: space-between;
}

.fx-ai-center{
  justify-content: center;
}

/* End of Utility */

.main-wrapper {
  padding-left: 100px;
  position: relative;

  /* Experimental */
  display: flex;
  flex-direction: column;
}

a,
a:visited {
  text-decoration: none;
  color: black;
}

div.subheader {
  background-color: var(--primary-purple);
  display: flex;
  flex-direction: row;
  padding: 0.5rem var(--layout-side-padding);
}

div.subheader>button {
  margin-bottom: 0.5rem;
}

/* INPUT */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* ==============
    Search Input 
    =============*/

div.search-input,
div.search-input-only {
  background-color: #ffffff41;
  padding: 0.5em 2em;
  border-radius: 1.3rem;
  color: white;
  min-width: 20%;
  position: relative;
}

div.search-input .hit-box {
  padding: 10px;
  display: none;
  min-width: 500px;
  min-height: 100px;
  background-color: white;
  position: absolute;
  z-index: 9999;
  top: 100%;
  right: 0;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid var(--clr-border);
}

div.search-input form,
div.search-input-only {
  display: flex;
}

div.search-input input,
div.search-input-only input {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0 1em;
  color: white;
  width: 100%;
}

div.search-input input::placeholder,
div.search-input-only input::placeholder {
  color: white;
}

/* ==============
    Buttons 
    =============*/

button,
.btn,
.rounded-btn-primary {
  outline: none;
  border: none;
  border-radius: 5px;
  font-weight: var(--medium);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em 1.5em;
  background-color: white;
  cursor: pointer;
  transition: 120ms;
  gap: 5px;
  user-select: none;
}

.btn-rel{
  position: relative;
}

.btn span:first-child {
  margin-right: 10px;
}

button.dark {
  background-color: var(--dark-purple);
}

.btn-dark {
  background-color: var(--dark-purple);
  color: white;
}

.btn-dark:hover {
  background-color: #000f94;
}

.btn-dark:active {
  background-color: #00084e;
}

.btn-green {
  background-color: var(--green);
  color: white;
}

.btn-green:hover {
  background-color: hsl(137, 55%, 70%);
}

.btn-green:active {
  background-color: hsl(137, 55%, 50%);
}

.btn-red {
  background-color: var(--red);
  color: white;
}

.btn-red:hover {
  background-color: hsl(0, 95%, 70%);
}

.btn-red:active {
  background-color: hsl(0, 95%, 50%);
}

.btn-blue {
  background-color: var(--sky-blue);
  color: white;
}

.btn-blue:hover {
  background-color: hsl(194, 100%, 70%);
}

.btn-blue:active {
  background-color: hsl(194, 100%, 50%);
}

.rounded-btn-primary {
  border-radius: 25px;
}

.btn-purple,
.rounded-btn-primary {
  color: white;
  background-color: var(--primary-purple);
}

.btn-purple:hover,
.rounded-btn-primary:hover {
  background-color: hsl(230, 83%, 70%);
}

.btn-purple:active,
.rounded-btn-primary:active {
  background-color: hsl(230, 83%, 50%);
}

.btn-orange {
  background-color: var(--orange);
  color: white;
}

.btn-orange:hover {
  background-color: hsl(22, 100%, 70%);
}

.btn-orange:active {
  background-color: hsl(22, 100%, 50%);
}

.btn-white {
  background-color: white;
  color: var(--primary-purple);
}

.btn-white:hover {
  background-color: hsl(0, 0%, 89%);
}

.btn-white:active {
  background-color: hsl(0, 0%, 85%);
}

button:disabled {
  filter: grayscale(100%);
}

/* ==============
    Inputs
    =============*/

input {
  padding: 0.5rem 1rem;
  border: 1px solid #00000041;
}

textarea {
  padding: 0.5rem 1rem;
  border: 1px solid #00000041;
}

.red-asterisk {
  color: var(--red);
}

/* ==============
    Selector 
    =============*/

select {
  padding: 0.5rem 1rem;
  outline: none;
  border: 1px solid #00000041;
  border-radius: 5px;
  cursor: pointer;
}

select:hover {
  background-color: #f7f7f7;
}

/* ==============
    Seperator 
    =============*/

.v-seperator {
  height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.418);
}

.h-seperator {
  height: 1px;
  width: 100%;
  background-color: var(--clr-border)
}

/* ==============
    Tabs 
    =============*/

.tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10%;
  padding: 0.5em 2em;
  background-color: #9aa9f6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  font-weight: var(--medium);
  cursor: pointer;
  transition: 120ms;
  position: relative;
  padding-right: 3rem;
  overflow: hidden;
  border-top-right-radius: 0;
  max-width: 209px;
}

.tab>span:last-child {
  min-width: max-content;
}

.tab::after {
  content: "";
  position: absolute;
  top: -17px;
  right: -40px;
  background-color: var(--primary-purple);
  height: 6rem;
  width: 4rem;
  transform: rotate(150deg);
}

.tab:hover,
.tab.active:hover {
  background-color: white;
  color: var(--primary-purple);
}

.tab.active {
  background-color: var(--background);
  color: var(--dark-purple);
}

.tab-contents {
  background-color: var(--background);
  margin: 0 var(--layout-side-padding);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  /* Stretch the contents */
  flex-grow: 1;
  position: relative;
}

/* ==============
    List
    =============*/
ul {
  list-style-type: none;
}

/* ==============
    Tables
    =============*/

.table {
  text-align: left;
  width: 100%;
  margin-top: 1rem;
}

.table>thead {
  border: none;
}

.table>thead th,
.table>tbody>tr>td {
  background-color: var(--dark-purple);
  color: white;
  font-weight: 700;
  padding: 10px;
  min-width: 200px;
}

.table>tbody>tr>td {
  background-color: white;
  color: black;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  border-collapse: collapse;
  font-weight: var(--regular);
}

.table>tbody>tr:hover>td {
  background-color: #dce2ff;
  /* cursor: pointer; */
  /* font-weight: var(--bold); */
}

.table tr.highlight>td {
  background-color: yellow;
}

.loading__table {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 2rem 1rem;
  border-radius: 5px;
  gap: 1rem;
}

/*================= 
   404 Page
   =================*/

.not-found {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



/*================= 
   Login Page
   =================*/

.login-parent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  position: relative;
  overflow: hidden;
}

.closure {
  position: absolute;
  width: 150%;
  height: 150%;
  background-color: var(--primary-purple);
}

.top-closure {
  /* top:-50%; */
  left: -75%;
  transform: rotate(-45deg);
}

.bottom-closure {
  /* top:-50%; */
  right: -75%;
  transform: rotate(-45deg);
}


.top-closure.collapse {
  top: -75%;
  left: -135%;
  transform: rotate(-15deg);
  transition: 1s;
}

.bottom-closure.collapse {
  top: 100%;
  right: -120%;
  transform: rotate(-110deg);
  transition: 1s;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem
}

.login-form>div {
  display: flex;
  align-items: stretch;
}

.login-form>div>span.inp {
  background-color: white;
  padding: .5rem 1rem;
  border: 1px solid rgb(223, 223, 223);
}

.login-form>div>input {
  padding: .5rem 1rem;
  width: 400px;
  border: 1px solid rgb(223, 223, 223);
  border-left: none;
  color: black;
}

.login-parent div.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.fp {
  align-items: center;
  color: var(--font-color);
  margin-left: 15px;
  gap: 1rem;
  cursor: pointer;
  transition: 120ms;
}

.fp:hover {
  color: black
}

.login-form>button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.login-form div.error-msg {
  color: red;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 15px;
  width: 400px;
}

/* Dialogs */

.dialog-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.212);
  z-index: 1000;
}

.wpd {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  padding: 2rem;
  max-height: 90vh;
}

.error-bar {
  background-color: var(--red);
  color: white;
  font-weight: var(--medium);
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 5px;
   /* las Update */
   display: flex;
   align-items: center;
  gap: 10px;
}

/* Form */
.buttons-dialog__form {
  display: flex;
  gap: 1rem;
  align-self: flex-end;
  margin-top: 10px;
}

.layout-dialog__form {
  display: flex;
  flex-direction: column;
  font-weight: var(--medium);
  gap: .5rem;
}

.layout-dialog__form input,
.layout-dialog__form option,
.layout-dialog__form select,
.layout-dialog__form textarea {
  font-size: 1.1rem;
}

/* Icons */
.material-icons.edit {
  color: var(--primary-purple);
}

.material-icons.delete {
  color: var(--red);
}

/* Status Component */
.status__dropdown,
.closing__dropdown {
  position: relative;
}

.status__dropdown :hover {
  color: var(--primary-purple);
  cursor: pointer;

}

.status__dropdown_menu,
.closing__dropdown_menu {
  position: absolute;
  right: 0;
  top: calc(100% + .25rem);
  box-shadow: var(--shadow-minimal);
  background-color: white;
  overflow: hidden;
  padding: .5rem;
  border-radius: 5px;
  transform-origin: top;
  transform: scale(0);
  transition: transform var(--transition-normal) ease-in-out;
  z-index: 99;
  color: black !important;
  font-weight: var(--regular) !important;
}

.status__dropdown_menu.active,
.closing__dropdown_menu.active {
  transform: scale(1);
}

.status__dropdown_menu:hover .closing__dropdown_menu:hover {
  color: black;
  font-weight: normal;
}

.status__dropdown_menu>p,
.closing__dropdown_menu>p {
  padding: .5rem 1rem;
  font-size: 1.2rem;
  border-radius: 5px;
}

.status__dropdown_menu>p:hover,
.closing__dropdown_menu>p:hover {
  color: black;
  background-color: var(--clr-hover-white);
  transition: var(--transition-normal);
}

@media only screen and (max-width: 1600px) {
  html {
    font-size: 11px;
  }

  .table>thead th,
  .table>tbody>tr>td {
    padding: 6px 10px;
    min-width: 100px;
  }

  button,
  .btn,
  .rounded-btn-primary {
    padding: 0.3em 1.1em;
    gap: 5px;
  }
}